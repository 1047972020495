import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import DayPickerToFrom from './DayPickerToFrom';
import CallApi from './../../utils/CallApi';
import { FormattedMessage } from 'react-intl';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';
import timesCircle from '@fortawesome/fontawesome-free-solid/faTimesCircle';
import questionCircle from '@fortawesome/fontawesome-free-solid/faQuestionCircle';
import imgCalendar from './../../images/svg/calendar-big.svg';
import imgArrowNext from './../../images/svg/fleche-droit.svg';
import { withSnackbar } from 'notistack';
import NbrGuests from './NbrGuests';
import SlotAuto from './SlotAuto';
import SlotAvgRotation from './SlotAvgRotation';
import SlotMaxGuestCount from './SlotMaxGuestCount';

const options = [
  { value: 15, label: '15min' },
  { value: 30, label: '30min' },
  { value: 45, label: '45min' },
  { value: 60, label: '1h' },
  { value: 120, label: '2h' },
  { value: 180, label: '3h' },
  { value: 240, label: '4h' },
  { value: 300, label: '5h' },
  { value: 360, label: '6h' },
  { value: 420, label: '7h' },
  { value: 480, label: '8h' },
  { value: 540, label: '9h' },
  { value: 600, label: '10h' },
  { value: 660, label: '11h' },
  { value: 720, label: '12h' },
  { value: 1440, label: '1 jour' },
  { value: 2880, label: '2 jours' },
];

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurantId: props.restaurantId,
      closingDays: props.closingDays,
      validationDelay: props.validationDelay,
      addClosingDays: false,
      errors: '',
      tokenError: '',
      isAdd: false,
      rangeToEdit: {
        from: null,
        to: null,
        id: null,
        position: null
      },
      information: props.specificInformation,
      success: false,
      slotAuto: props.slotAuto,
      slotAvgRotation: props.slotAvgRotation,
      slotMaxGuestCount: props.slotMaxGuestCount,
    };
  }

  getSnapshotBeforeUpdate(nextProps, prevProps) {
    if (
      nextProps.closingDays !== prevProps.closingDays ||
      nextProps.validationDelay !== prevProps.validationDelay ||
      nextProps.specificInformation !== prevProps.specificInformation
    ) {
      return {
        closingDays: prevProps.closingDays,
        validationDelay: prevProps.validationDelay,
        specificInformation: prevProps.specificInformation
      };
    }
    return null;
  }

  /**
   * display the date picker to add new closing days
   * @param {*} bool: true === add new date and false === edit date range
   * @param index: if is false, get the index to place the calendar to the right position
   * @param rangeToEdit: object of the date to edit
   */
  handleDisplay = (e, bool, index, rangeToEdit) => {
    e.preventDefault();
    this.setState({
      addClosingDays: true,
      isAdd: bool,
      rangeToEdit: {
        from: rangeToEdit ? rangeToEdit.day1 : null,
        to: rangeToEdit ? rangeToEdit.day2 : null,
        id: rangeToEdit ? rangeToEdit.id : null,
        position: index || index === 0 ? `index-${index}` : null
      }
    });
  };
  cancelCalendar = e => {
    e.preventDefault();
    this.setState({
      addClosingDays: false
    });
  };

  handleInputChange = e => {
    const target = e.target;
    const value = target.value;

    this.setState({ information: value });
  };

  saveParticularInfo = async e => {
    e.preventDefault();

    const dataSent = this.submitData(
      this.state.information,
      'specificInformation'
    );
    if (dataSent) {
      this.setState({
        success: true
      });
      this.displaySuccess();
      this.props.onUpdateInfo(this.state.information);
    } else {
      this.displayError();
    }
  };

  handleChangeDelay = async e => {
    e.preventDefault();

    const validationDelay = e.target.value;
    this.setState({ validationDelay: parseInt(e.target.value, 10) });
    const dataSent = this.submitData(validationDelay, 'validationDelay');

    if (dataSent) {
      this.setState(
        {
          success: true
        },
        () => {
          this.displaySuccess();
          this.props.onUpdateDelay(validationDelay);
        }
      );
    } else {
      this.displayError();
    }
  };

  deleteClosingDay = async (e, id, index) => {
    e.preventDefault();
    try {
      await CallApi('delete', 'closingDays', id, '', this.props.token);

      this.setState(
        {
          closingDays: this.state.closingDays.filter((x, i) => i !== index)
        },
        () => {
          this.displaySuccess();
          this.props.onUpdateClosingDays(this.state.closingDays);
        }
      );
    } catch (err) {
      this.displayError();
    }
  };

  /**
   * close the calendar and add the date range in local
   */
  handleAddClosingDays = async rangeData => {
    const [...closingDays] = this.state.closingDays;

    const range = Object.assign(rangeData, {
      restaurantId: this.state.restaurantId
    });
    try {
      const newClosingDays = await CallApi(
        'post',
        'closingDays',
        '',
        range,
        this.props.token
      );
      closingDays.push(newClosingDays[0]);
      this.setState(
        {
          addClosingDays: false,
          closingDays: closingDays
        },
        () => {
          this.displaySuccess();
          this.props.onUpdateClosingDays(this.state.closingDays);
        }
      );
    } catch (e) {
      this.displayError();
    }
  };

  handleChangeNbrGuests = async (nbrGuests) => {
    const dataSent = await this.submitData(nbrGuests, 'nbrGuests');
    if (dataSent) {
      this.displaySuccess();
      this.props.onUpdateNbrGuest(nbrGuests);
    } else {
      this.displayError();
    }
  };

  handleChangeSlotAuto = async (slotAuto) => {
    const dataSent = await this.submitData(slotAuto, 'slot_auto');
    if (dataSent) {
      this.displaySuccess();
      this.props.onUpdateSlotAuto(slotAuto);
    } else {
      this.displayError();
    }
  };

  handleChangeSlotAvgRotation = async (slotAvgRotation) => {
    const dataSent = await this.submitData(+slotAvgRotation, 'slot_avg_rotation');
    if (dataSent) {
      this.displaySuccess();
      this.props.onUpdateSlotAvgRotation(slotAvgRotation);
    } else {
      this.displayError();
    }
  };

  handleChangeSlotMaxGuestCount = async (slotMaxGuestCount) => {
    const dataSent = await this.submitData(slotMaxGuestCount, 'slot_max_guest_count');
    if (dataSent) {
      this.displaySuccess();
      this.props.onUpdateSlotMaxGuestCount(slotMaxGuestCount);
    } else {
      this.displayError();
    }
  };

  displaySuccess = () => {
    this.props.enqueueSnackbar(
      <FormattedMessage
        id="common.success"
        defaultMessage="Vos changements ont bien été pris en compte."
      />,
      { variant: 'success' }
    );
  };

  displayError = () => {
    this.props.enqueueSnackbar(
      <FormattedMessage
        id="common.errorRequest"
        defaultMessage="Oups... nous rencontrons un problème avec l'enregistrement de vos données"
      />,
      { variant: 'error' }
    );
  };

  submitData = async (datas, name) => {
    const res = await CallApi(
      'put',
      'restaurants',
      this.state.restaurantId,
      { [name]: datas },
      this.props.token
    );

    return !!res[0];
  };

  render() {
    const { nbrGuests } = this.props;
    const displayAddClosingDays = rangeToEdit => {
      return (
        <div
          id="calendar"
          className={
            rangeToEdit && rangeToEdit.position ? rangeToEdit.position : ''
          }
        >
          <button className="cta-delete" onClick={this.cancelCalendar}>
            <FontAwesomeIcon size="2x" icon={timesCircle} />
          </button>
          <DayPickerToFrom
            restaurantId={this.state.restaurantId}
            onUpdateClosingDays={this.onUpdateClosingDays}
            token={this.props.token}
            className="date-picker"
            handleAddClosingDays={this.handleAddClosingDays}
          />
        </div>
      );
    };

    const closingDays = () => {
      if (this.state.closingDays.length) {
        return this.state.closingDays.map((day, i) => {
          return (
            <div className="pure-g closing-days" key={`closing-days-${i}`}>
              <div className="start">
                <img src={imgCalendar} className="calendar" alt="calendar" />
                <div className="select-styles">
                  <button className="select" value={day.day1}>
                    {moment(day.day1).format('DD/MM')}
                  </button>
                </div>
              </div>
              <div className="end">
                <img src={imgArrowNext} className="arrow" alt="arrow" />
                <div className="select-styles">
                  <button className="select" value={this.state.validationDelay}>
                    {moment(day.day2).format('DD/MM')}
                  </button>
                </div>
              </div>
              <button
                onClick={e => this.deleteClosingDay(e, day.id, i)}
                className="cta-delete"
              >
                <FontAwesomeIcon
                  size="2x"
                  className="admin-fa-icon delete-date"
                  icon={faTimes}
                />
              </button>
            </div>
          );
        });
      }
    };

    const particularCase = () => {
      return (
        <div className="bloc-label particular-info">
          <div className="header">
            <h4>
              <FormattedMessage
                id="info.addParticularCaseInfo"
                defaultMessage="Préciser les demandes particulières"
              />{' '}
            </h4>
            <div className="tooltip">
              <FontAwesomeIcon icon={questionCircle} />
              <span className="tooltipText">
                <FormattedMessage
                  id="info.addParticularCaseInfoToolTip"
                  defaultMessage="Ce texte s’affichera au niveau du champ “Demandes particulières” que les clients peuvent renseigner lors de leur demande de réservation"
                />
              </span>
            </div>
          </div>

          <FormattedMessage
            id="info.addParticularCaseInfoPlaceholder"
            defaultMessage="exemple : pas de réservation en terrasse possible en hiver."
          >
            {msg => (
              <textarea
                className="textarea"
                placeholder={msg}
                name="information"
                onChange={this.handleInputChange}
                value={this.state.information}
              />
            )}
          </FormattedMessage>

          {this.state.success ? (
            <p className="success">
              <FormattedMessage
                id="common.success"
                defaultMessage="Vos changements ont bien été pris en compte."
              />
            </p>
          ) : (
            ''
          )}
          <button
            className="is-red-line save"
            onClick={this.saveParticularInfo}
          >
            <FormattedMessage id="common.validate" defaultMessage="valider" />
          </button>
        </div>
      );
    };

    return (
      <React.Fragment>
        {this.state.errors ? <p>{this.state.errors} </p> : ''}

        <div id="settings">
          <h3>
            <FormattedMessage
              id="settings.title"
              defaultMessage="Réglages de votre module de réservation"
            />
          </h3>
          <div className="pure-g">
            <div className="pure-u-1 pure-u-lg-1-3">
              <section id="delay">
                <h4>
                  <FormattedMessage
                    id="seetings.subTitle"
                    defaultMessage="Délai minimum de validation d’une réservation"
                  />
                </h4>
                <p>
                  <FormattedMessage
                    id="settings.delay"
                    defaultMessage="Vos clients pourront faire une demande de réservation jusqu’à"
                  />
                </p>
                <div className="validation-delay">
                  <div className="select-styles">
                    <select
                      className="select"
                      value={this.state.validationDelay}
                      onChange={this.handleChangeDelay}
                    >
                      {options.map((time, i) => {
                        return (
                          <option
                            key={i}
                            value={time.value}
                            className={classNames(
                              { item: true },
                              {
                                selected:
                                  this.state.validationDelay !== 0 &&
                                  this.state.validationDelay === time.value
                              }
                            )}
                          >
                            {time.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <p>
                    <FormattedMessage
                      id="settings.delay2"
                      defaultMessage="à l'avance"
                    />
                  </p>
                </div>
              </section>
              <section>{particularCase()}</section>
            </div>
            <div className="pure-u-lg-2-24" />
            <div className="pure-u-1 pure-u-lg-10-24">
              <section className="content-closing-days">
                <h4>
                  <FormattedMessage
                    id="settings.close"
                    defaultMessage="Période(s) de fermeture"
                  />
                </h4>
                <div className="">
                  <div className="pure-g closing-days">
                    <div className="pure-u-6-24 pure-md-5-24 start">
                      <p className="subtitle">
                        <FormattedMessage
                          id="settings.addDateStart"
                          defaultMessage="Date du début"
                        />
                      </p>
                    </div>
                    <div className="pure-u-2-24 pure-md-2-24 container-arrow" />
                    <div className="pure-u-10-24  pure-md-5-24 end">
                      <p className="subtitle">
                        <FormattedMessage
                          id="settings.addDateEnd"
                          defaultMessage="Date de fin"
                        />
                      </p>
                    </div>
                  </div>
                  {closingDays()}
                </div>

                <button
                  className="is-red-line"
                  onClick={e => this.handleDisplay(e, true, null)}
                >
                  <FormattedMessage
                    id="settings.add"
                    defaultMessage="ajouter une date"
                  />
                </button>
                {this.state.addClosingDays === true
                  ? displayAddClosingDays()
                  : ''}
              </section>
              <NbrGuests
                handleChangeNbrGuests={this.handleChangeNbrGuests}
                selectedValue={nbrGuests}
              />
              <SlotAuto
                slotAuto={this.state.slotAuto}
                onSlotAutoChange={this.handleChangeSlotAuto}
              />
              <SlotAvgRotation 
                selectedValue={this.state.slotAvgRotation}
                onSlotAvgRotationChange={this.handleChangeSlotAvgRotation}
              />
              <SlotMaxGuestCount
                onSlotMaxGuestCountChange={this.handleChangeSlotMaxGuestCount}
                selectedValue={this.state.slotMaxGuestCount}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  static defaultProps = {
    restaurantId: 1,
    closingDays: [],
    validationDelay: 0,
    token: ''
  };
}

Settings.propTypes = {
  restaurantId: PropTypes.string.isRequired,
  closingDays: PropTypes.array,
  validationDelay: PropTypes.number,
  token: PropTypes.string.isRequired,
  onUpdateDelay: PropTypes.func.isRequired,
  onUpdateClosingDays: PropTypes.func.isRequired,
  onUpdateInfo: PropTypes.func.isRequired,
  specificInformation: PropTypes.string
};

export default withSnackbar(Settings);
