import React, { useState, useEffect } from "react";
import options from "../../utils/Times";
import { FormattedMessage } from "react-intl";

const SlotAvgRotation = ({selectedValue, onSlotAvgRotationChange}) => {
  const [value, setValue] = useState(selectedValue);

  useEffect(() => {
    if (value !== selectedValue) onSlotAvgRotationChange(value);
  }, [value, onSlotAvgRotationChange, selectedValue]);

  const handleSelectChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <section>
      <h4>
        <FormattedMessage
          id="settings.slotAvgRotation"
          defaultMessage="Durée moyenne d'un créneau"
        />
      </h4>
      <div>
        <select
          name="slotAvgRotation"
          onChange={(e) => handleSelectChange(e)}
          value={value}
          className="slot-avg-rotation"
        >
          {options.map((option, index) => (
            <option
              key={index}
              value={option.value}
              className="slotAvgRotation"
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </section>
  );
}

export default SlotAvgRotation;