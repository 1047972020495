import axios from "axios";

class HttpModule {
  constructor(options = {}) {
    const { throwError, ...axiosConfig } = options;
    this.throwError = options.throwError || false;
    this.axiosInstance = axios.create(axiosConfig);
  }

  handleAxiosError(err) {
    if (err.response) {
      return {
        status: err.response.status,
        statusText: err.response.statusText,
        data: err.response.data.data,
        error: err.response.data.error,
      };
    }
    return { status: err.code, url: err.config?.url };
  }

  async request(method, url, data, config = {}) {
    const requestConfig = {
      method,
      url,
      validateStatus(status) {
        return status >= 200 && status < 400;
      },
      ...config,
    };

    if (method === "GET" || method === "DELETE") {
      requestConfig.params = data;
    } else {
      requestConfig.data = data;
    }

    try {
      const response = await this.axiosInstance(requestConfig);
      return response.data;
    } catch (err) {
      const error = this.handleAxiosError(err);
      console.error(error);

      const shouldThrow = config.throwError !== undefined ? config.throwError : this.throwError;
      if (shouldThrow) {
        throw error;
      }
      return error;
    }
  }

  async get(url, params, config) {
    return this.request("GET", url, params, config);
  }

  async post(url, data, config) {
    return this.request("POST", url, data, config);
  }

  async put(url, data, config) {
    return this.request("PUT", url, data, config);
  }

  async patch(url, data, config) {
    return this.request("PATCH", url, data, config);
  }

  async delete(url, params, config) {
    return this.request("DELETE", url, params, config);
  }

  setHeader(key, value) {
    this.axiosInstance.defaults.headers.common[key] = value;
  }

  removeHeader(key) {
    delete this.axiosInstance.defaults.headers.common[key];
  }
}

export const http = new HttpModule({ throwError: true });
