import React from "react";
import { Redirect, Route } from "react-router-dom";
import { UnauthorizedServicePage } from "@capps/subuser-tools";
import { decodeToken, getHostUrl } from "../utils/function";
import useAuth from "../hooks/useAuth";

const PrivateRoute = ({ component: Component, ...rest }) => {

	const { isLogged, tryLogin, unauthorized } = useAuth();

	const renderRedirect = (props) => {

		if (isLogged) {
      const token = sessionStorage.getItem("accessToken");

      if (token && token.length) {
        const accessToken = decodeToken(token);
        const groupNames = accessToken.groups.map((g) => g.name);
        const hasNewResa = groupNames.includes("new_reservation")
        
        if (hasNewResa) {
          const redirectUrl = `${getHostUrl("home")}/bookings`;
          window.location.replace(redirectUrl);
          return null;
        }
      }
			return <Component {...props} />
		}

		if (unauthorized) {
			return <UnauthorizedServicePage />
		}

		// Make error page
		if (tryLogin && !isLogged) {
			return <Redirect to={{ pathname: "/admin", state: {from: props.location} }} />
		}

		return <></>;
	}

	return <Route
		{...rest}
		render={(props) => renderRedirect(props)}
	/>
};

export default PrivateRoute;