import React from "react";
import { Switch } from "@capps/laddition-storybook";
import { FormattedMessage } from "react-intl";

const SlotAuto = ({ slotAuto, onSlotAutoChange }) => {
  const handleSwitchChange = (e) => {
    onSlotAutoChange(e.target.checked);
  };

  const label = slotAuto ? (
    <FormattedMessage id="common.yes" defaultMessage="Oui" />
  ) : (
    <FormattedMessage id="common.no" defaultMessage="Non" />
  )

  return (
    <section className="container-select-nbr-guests">
      <h4>
        <FormattedMessage
          id="settings.autoAccept"
          defaultMessage="Acceptation automatique des réservations"
        />
      </h4>
      <div className="">
        <Switch
          name="slotAuto"
          checked={slotAuto}
          label={label}
          onChange={handleSwitchChange}
        />
      </div>
    </section>
  );
};

export default SlotAuto;
