import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";

const SlotMaxGuestCount = ({selectedValue, onSlotMaxGuestCountChange}) => {
  const [value, setValue] = useState(selectedValue);

  useEffect(() => {
    const validValue = !!value && value > 0
    let timer = setTimeout(() => {
      if (validValue && value !== selectedValue) onSlotMaxGuestCountChange(value);
    }, 1000);
    return () => clearTimeout(timer);
  }, [value, onSlotMaxGuestCountChange, selectedValue]);

  const handleSelectChange = (e) => {
    const inputValue = +e.target.value;
    const maxGuest = 9999;
    setValue(inputValue < 10000 ? inputValue : maxGuest)
  };

  const handlePreventNullValues = () => {
    if (!value || value < 1) {
      setValue(selectedValue);
    }
  };

  return (
    <section className="container-select-nbr-guests">
      <h4>
        <FormattedMessage
          id="settings.slotMaxGuestCount"
          defaultMessage="Durée moyenne d'un créneau"
        />
      </h4>
      <div className="input-select-nbr-guests">
        <div className="select-styles">
          <input
            type="number"
            min="1"
            max="9999"
            className="input-nbr-guests"
            onChange={(e) => handleSelectChange(e)}
            value={value}
            onBlur={() => handlePreventNullValues()}
          />
        </div>
        <span>
          <FormattedMessage id="settings.people" defaultMessage="personnes" />
        </span>
      </div>
    </section>
  );
}

export default SlotMaxGuestCount;