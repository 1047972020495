import React, { useEffect, useState } from 'react';
import { CalendarDays, CircleCheckBig, Clock, MapPin, Users } from 'lucide-react';
import { Button, Loader } from '@capps/laddition-storybook';
import { FormattedMessage } from 'react-intl';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';

import { http } from '../../utils/http';

dayjs.extend(utc)
dayjs.extend(timezone);

export default function BookingCancel(props) {
  const [loading, setLoading] = useState(true);
  const [done, setDone] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [alreadyCancelled, setAlreadyCancelled] = useState(false);
  const [alreadyExpired, setAlreadyExpired] = useState(false);
  const [detail, setDetail] = useState({});
  const [error, setError] = useState(false);

  useEffect(() => {
    async function fetch() {
      try {
        const token = props.match.params.token;
        const response = await http.get(`/api/bookings/cancel/${token}`);
        const { data, isCancelled, isExpired} = response;

        setDetail(data);
        setAlreadyExpired(isExpired);
        setAlreadyCancelled(isCancelled);
      } catch (response) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    fetch();
  }, [])

  const handleSubmit = async () => {
    setCancelling(true);
    try {
      const token = props.match.params.token;
      await http.post(`/api/bookings/cancel/${token}`);
      // Small throttle for UX
      setTimeout(() => {
        setDone(true);
        setCancelling(false);
       }, 500);
    } catch (error) {
      console.log(error);
      setError(true);
    }
  }

  if (loading) {
    return <div className="cancellation-page"><Loader /></div>;
  }

  if (error) {
    return (
      <div className="cancellation-page text-center">
        <FormattedMessage id="bookingCancel.error.unknown.text1" /><br />
        <FormattedMessage id="bookingCancel.error.unknown.text2" />
      </div>
    );
  }

  if (done) {
    return (
      <div className="cancellation-page text-center">
        <div className="cancellation-card">
          <div className="cancellation-card__content">
            <h1 className="cancellation-card__title">
              <FormattedMessage id="bookingCancel.title" />
            </h1>
          </div>
          <div className="cancellation-card__details">
            <div className="cancellation-card__splash">
              <CircleCheckBig />
              <div className="cancellation-card__splash__text">
                <FormattedMessage id="bookingCancel.success" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const dateUTC = dayjs.unix(detail.date).utc().format("YYYY-MM-DD HH:mm");
  const dateTZ = dayjs.tz(dateUTC, detail.timezone);
  const day = dayjs(dateTZ).format("DD/MM/YYYY");
  const hour = dayjs(dateTZ).format("HH:mm");

	return (
    <div className="cancellation-page">
      <div className="cancellation-card">
        <div className="cancellation-card__content">
          <h1 className="cancellation-card__title">
            <FormattedMessage id="bookingCancel.title" />
          </h1>
          <div className="cancellation-card__details">
            <p className="cancellation-card__restaurant">{detail.name}</p>
            <div className="cancellation-card__info">
              <MapPin className="cancellation-card__info-icon" />
              {detail.address}
            </div>
            <div className="cancellation-card__info">
              <CalendarDays className="cancellation-card__info-icon" />
              {day}
            </div>
            <div className="cancellation-card__info">
              <Clock className="cancellation-card__info-icon" />
              {hour}
            </div>
            <div className="cancellation-card__info">
              <Users className="cancellation-card__info-icon" />
              {detail.guests} <FormattedMessage id="bookingCancel.people" />
            </div>
          </div>
          
          <div className="cancellation-card__message">
            {alreadyExpired ? (
              <p className="cancellation-card__message--expired">
                <FormattedMessage id="bookingCancel.error.alreadyExpired" />
                <br />
                <br />
                <FormattedMessage id="bookingCancel.error.alreadyExpired.call" />
                &nbsp;<a href={`tel:${detail.phone}`}>{detail.phone}</a>
              </p>
            ): null}
            {alreadyCancelled ? (
              <p className="cancellation-card__message--expired">
                <FormattedMessage id="bookingCancel.error.alreadyCancelled" />
              </p>
            ): null}
            {!alreadyExpired && !alreadyCancelled ? (
              <p>
                <FormattedMessage id="bookingCancel.confirm" />
              </p>
            ): null}
          </div>
        </div>
        <div className="cancellation-card__actions">
          {!alreadyExpired && !alreadyCancelled ? (
            <Button 
              color="secondary"
              onClick={handleSubmit}
              loading={cancelling}
              disabled={cancelling}
            >
              <FormattedMessage id="bookingCancel.button" />
            </Button>
          ): null}
        </div>
      </div>
    </div>
	);
}
